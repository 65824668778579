<!--
 * @Author: 候怀烨
 * @Date: 2021-01-07 16:40:27
 * @LastEditTime: 2021-01-07 16:41:08
 * @LastEditors: Please set LastEditors
 * @Description: 更多活动
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\moreEvents\index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
  export default {}
</script>

<style></style>
